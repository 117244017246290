import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import LongForm from '../../components/long-form';

import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';

import DetailsList from '../../styles/typography/details-list';

import AdaptarFunders from '../../media/layout/adaptar/barra-adaptar.png';

const Adaptar = ({ location }) => {
  return (
    <Layout location={location} title='Programa Adaptar'>
      <Inpage>
        <InpageHeader leadLayout='aside'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Programa Adaptar</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              With support from Lisboa 2020, PT2020 and the FEDER program from
              the European Union, we adapted our Lisbon office to ensure a safe
              post-COVID workplace.
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <LongForm>
            <img src={AdaptarFunders} />
            <DetailsList>
              <dt>Designação do projeto</dt>
              <dd>Development Seed adaptation for COVID-19</dd>
              <dt>Código do projeto</dt>
              <dd>LISBOA-02-08B9-FEDER-062207</dd>
              <dt>Objetivo Principal</dt>
              <dd>Reforçar a competitividade das PME</dd>
              <dt>Região de Intervenção</dt>
              <dd>Lisboa</dd>
              <dt>Entidade Beneficiária</dt>
              <dd>Development Seed, Unipessoal Lda</dd>
              <dt>Data de aprovação</dt>
              <dd>29/06/2020</dd>
              <dt>Data de início</dt>
              <dd>22/05/2020</dd>
              <dt>Data de conclusão</dt>
              <dd>20/11/2020</dd>
              <dt>Custo total elegível</dt>
              <dd>16.875,00 euros</dd>
              <dt>Cofinanciamento da União Europeia</dt>
              <dd>FEDER - 8.437,50 euros</dd>
              <dt>Objetivos estratégicos</dt>
              <dd>
                Adaptação da atividade económica face ao novo contexto criado
                pela COVID -19
              </dd>
            </DetailsList>
          </LongForm>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Adaptar;

Adaptar.propTypes = {
  location: PropTypes.object
};

import styled from 'styled-components';

import { glsp, media, themeVal } from '@devseed-ui/theme-provider';

const DetailsList = styled.dl`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp(0.25, 1)};

  ${media.smallUp`
    grid-template-columns: minmax(min-content, max-content) 1fr;
    grid-gap: ${glsp(0.5, 2)};
  `}

  dt {
    font-family: ${themeVal('type.heading.family')};
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    font-variation-settings: 'wdth' 32, 'wght' 608;

    ${media.mediumUp`
      padding: 0.25rem 0;
    `}
  }

  dd {
    font-weight: ${themeVal('type.base.bold')};
  }
`;

export default DetailsList;
